<template>
  <section class="checkCandidate-index" v-loading="loading">
    <div class="header">
      <div class="tools">
        <el-button
          :type="componentName==='baseInfo'?'primary':''"
          size="small"
          :class="componentName==='baseInfo'?'':'btn_active'"
          @click="changeComponent('baseInfo','修改基本信息',1)"
        >基本信息</el-button>
        <el-button
          :type="componentName==='companyInfo'?'primary':''"
          size="small"
          :class="componentName==='companyInfo'?'':'btn_active'"
          @click="changeComponent('companyInfo','修改单位信息',2)"
        >单位信息</el-button>
        <el-button
          :type="componentName==='perfectInfo'?'primary':''"
          size="small"
          :class="componentName==='perfectInfo'?'':'btn_active'"
          @click="changeComponent('perfectInfo','修改个人简历',3)"
        >个人简历</el-button>
        <el-button
          :type="componentName==='upload'?'primary':''"
          size="small"
          :class="componentName==='upload'?'':'btn_active'"
          @click="changeComponent('upload','上传文件',4)"
        >上传文件</el-button>
      </div>
      <div class="submit">
        <el-button size="small" @click="cancel">取消</el-button>
        <el-button type="primary" size="small" @click="check">提交修改</el-button>
      </div>
    </div>
    <div class="main">
      <div class="main-header">
        <div class="router2-name">{{title}}</div>
      </div>
      <baseInfo
        :info="info"
        v-show="componentName==='baseInfo' "
        :Dictionary="Dictionary"
        @changeComponent="changeComponent"
        @changeInfo="changeInfo"
        @submit="submit"
        @canChangeComponent="canChangeComponent"
        ref="baseInfo"
      ></baseInfo>
      <companyInfo
        :info="info"
        v-show="componentName==='companyInfo' "
        :Dictionary="Dictionary"
        @changeComponent="changeComponent"
        @changeInfo="changeInfo"
        @submit="submit"
        @canChangeComponent="canChangeComponent"
        ref="companyInfo"
      ></companyInfo>
      <perfectInfo
        :info="info"
        v-show="componentName==='perfectInfo'"
        :Dictionary="Dictionary"
        @changeComponent="changeComponent"
        @changeInfo="changeInfo"
        @submit="submit"
        @canChangeComponent="canChangeComponent"
        ref="perfectInfo"
      ></perfectInfo>
      <upload
        :info="info"
        v-show="componentName==='upload'"
        :Dictionary="Dictionary"
        @changeComponent="changeComponent"
        @changeInfo="changeInfo"
        @submit="submit"
        @canChangeComponent="canChangeComponent"
        ref="upload"
      ></upload>
    </div>
  </section>
</template>

<script>
import baseInfo from './baseInfo.vue';
import companyInfo from './companyInfo.vue';
import perfectInfo from './personalResume.vue';
import upload from './upload.vue';
import Candidate from '@/api/choose/candidate';
export default {
  name: '',
  components: {
    baseInfo,
    companyInfo,
    perfectInfo,
    upload,
  },
  data() {
    return {
      type: '随机抽选',
      title: '修改基本信息',
      componentName: '',
      step: 1,
      info: {},
      changeLogo: true,
      form_list: ['baseInfo', 'companyInfo', 'perfectInfo', 'upload'],
      Dictionary: {},
      loading: false,
      submitFun: '',
      update: true,
    };
  },
  methods: {
    canChangeComponent(data) {
      this.changeLogo = data;
    },
    check() {
      let index = this.form_list.findIndex((item) => item === this.componentName);
      this.$refs[this.componentName].submit();
      if (!this.changeLogo) {
        return;
      }
      for (let i in this.form_list) {
        if (i === index) {
          return;
        }
        this.componentName = this.form_list[i];
        this.$refs[this.form_list[i]].submit();
        if (!this.changeLogo) {
          return;
        }
      }
      if (this.changeLogo) {
        return this.submit();
      }
    },
    submit() {
      this.loading = true;
      Candidate[this.submitFun](this.info)
        .then(() => {
          this.$message.success(
            this.submitFun === 'update_sfs'
              ? `修改{${this.info.xm}}的个人资料成功`
              : `添加候选人{${this.info.xm}}成功，并且状态为司法所已通过`
          );
          this.$emit('changeComponent', 'index');
        })
        .finally(() => {
          this.loading = false;
        });
    },
    cancel() {
      this.$emit('changeComponent', 'index');
    },
    // 初始化，用身份证获取详情
    toggle(data) {
      console.log(data);
      let { created } = data;
      if (created) {
        this.submitFun = 'create_hxr_sfs';
        let info = {
          bmfs: 3,
          byyx: null,
          byzy: null,
          cjgzsj: null,
          dwxz: null,
          dzyx: null,
          grzdsxbh: null,
          grzp: null,
          gzdw: null,
          hjd: null,
          hjdxxdz: null,
          jkzk: null,
          jtjzyshgx: null,
          jzd: null,
          jzdxxdz: null,
          lxdh: null,
          mz: null,
          qqhm: null,
          rddb: null,
          sddjl: null,
          sfcwhgzry: null,
          sfflgzz: null,
          sflyys: null,
          sfxbwy: null,
          sfzbry: null,
          sfzghm: null,
          sfzhm: null,
          sfztxm: null,
          shjz: null,
          sjhm: null,
          txdz: null,
          txdzxxdz: null,
          whcd: null,
          whcd_a: null,
          wxh: null,
          xb: null,
          xm: null,
          yzbm: null,
          zwjb: null,
          zxwy: null,
          zylb: null,
          zzmm: null,
        };
        this.info = info;
        this.form_list.forEach((item) => {
          this.$refs[item].toggle(info);
        });
      } else {
        this.submitFun = 'update_sfs';
        Candidate.detail_sfs(data.sfzhm).then((res) => {
          let info = JSON.parse(JSON.stringify(res.person));
          // 修改需要移除_id
          delete info._id;
          this.info = info;
          this.form_list.forEach((item) => {
            this.$refs[item].toggle(info);
          });
        });
      }

      return this.changeComponent('baseInfo', '修改基本信息', 1);
    },
    changeComponent(name, title, step) {
      if (name === this.componentName) {
        return;
      }
      // 切换的时候，必须通过当前表单的条件
      if (this.$refs[this.componentName]) {
        this.$refs[this.componentName].submit();
      }
      if (!this.changeLogo) {
        return;
      } else {
        this.componentName = name;
        this.title = title;
        this.step = step;
        this.$nextTick(() => {
          if (this.$refs[this.componentName]) {
            this.$refs[this.componentName].toggle(this.info);
          }
        });
      }
    },
    changeInfo(data) {
      Object.assign(this.info, data);
    },
  },
  mounted() {},
  created() {
    this.Dictionary = this.$store.getters.dic;
  },
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.checkCandidate-index {
  display: flex;
  flex-direction: column;
  height: 100%;
  box-sizing: border-box;
  .header {
    margin: 20px 0;
    display: flex;
    justify-content: space-between;
  }
  .main {
    background-color: #fff;
    box-sizing: border-box;
    position: relative;
    flex: 1;
    padding: 0 20px;
    .main-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: $h4;
      padding: 15px 0;
      font-weight: bold;
    }

    .form {
      padding: 10px;
      .el-form {
        border-bottom: none;
        .header-bgc {
          background-color: $border4;
        }
      }
    }
  }
}
</style>
